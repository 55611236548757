/**
* @author necsus <mail@necsus.com>
* @copyright ksze 2020
* @version 01.02.10
* @license licenses.txt
*
* @date 2020-09-30 09:18:03
**/

import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

const routes: Routes = [
	{
		path: "",
		redirectTo: "start",
		pathMatch: "full"
	},
	{
		path: "about-us",
		loadChildren: () => import("./pages/about-us/about-us.module").then(m => m.AboutUsPageModule)
	},
	{
		path: "cateogry",
		loadChildren: () => import("./pages/cateogry/cateogry.module").then(m => m.CateogryPageModule)
	},
	{
		path: "faqs",
		loadChildren: () => import("./pages/faqs/faqs.module").then(m => m.FaqsPageModule)
	},
	{
		path: "home",
		loadChildren: () => import("./pages/home/home.module").then(m => m.HomePageModule)
	},
	{
		path: "karte",
		loadChildren: () => import("./pages/karte/karte.module").then(m => m.KartePageModule)
	},
	{
		path: "liste-bookmarks",
		loadChildren: () => import("./pages/liste-bookmarks/liste-bookmarks.module").then(m => m.ListeBookmarksPageModule)
	},
	{
		path: "liste-detail",
		loadChildren: () => import("./pages/liste-detail/liste-detail.module").then(m => m.ListeDetailPageModule)
	},
	{
		path: "liste-detail/:id",
		loadChildren: () => import("./pages/liste-detail/liste-detail.module").then(m => m.ListeDetailPageModule)
	},
	{
		path: "liste",
		loadChildren: () => import("./pages/liste/liste.module").then(m => m.ListePageModule)
	},
		{
		path: "p-language",
		loadChildren: () => import("./pages/p-language/p-language.module").then(m => m.PLanguagePageModule)
	},
	{
		path: "privacy-policy",
		loadChildren: () => import("./pages/privacy-policy/privacy-policy.module").then(m => m.PrivacyPolicyPageModule)
	},
	{
		path: "start",
		loadChildren: () => import("./pages/start/start.module").then(m => m.StartPageModule)
	},
	{
		path: "content/:id",
		loadChildren: () => import("./pages/content/content.module").then(m => m.ContentPageModule)
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
