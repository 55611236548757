/**
* @author necsus <mail@necsus.com>
* @copyright ksze 2020
* @version 01.02.10
* @license licenses.txt
*
* @date 2020-09-30 09:18:03
**/

import { Component } from "@angular/core";
import { MenuController } from "@ionic/angular";
import { AppSideMenus } from "./app.side-menus";

import { Platform, NavController } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { InAppBrowser,InAppBrowserOptions } from "@ionic-native/in-app-browser/ngx";
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from "@angular/router";
import { Device } from "@ionic-native/device/ngx";
import { Globals } from "./class/globals/globals";
import { environment } from "./../../src/environments/environment";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateService } from "@ngx-translate/core";




@Component({
	selector: "app-root",
	templateUrl: "app.component.html"
})
export class AppComponent {

	appTitle:string = "menu.title";
	appSubTitle:string = "menu.subtitle";
	appMenus:any = [] ;

	isDE = true;

	/**
	* Ksze:constructor()
	**/

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private menuController: MenuController,
		private platform: Platform,
		private storage: Storage,
		private statusBar: StatusBar,
		private navController: NavController,
		private splashScreen: SplashScreen,
		private device: Device,
		private appSideMenus: AppSideMenus,
		private globals: Globals,
		private translate: TranslateService){
			//initialization items for static menu
			this.appMenus = this.appSideMenus.items;
			//initialization app
			this.initializeApp();
	}
	

	/**
	* Ksze:initializeApp()
	**/

	initializeApp() {
		this.platform.ready().then(() => {

			// set status bar
			this.statusBar.backgroundColorByHexString("#004080");

			// hide splashscreen
			this.splashScreen.hide();
		});


		// Translate Module
		this.initTranslate();
	}


	initTranslate() {
		// Set the default language for translation strings, and the current language.
		console.log('InitTranslate');
		this.translate.setDefaultLang('de');

		this.storage.get('settings:language').then(lang => {
			console.log('Language stored: ', lang);
			if (lang == null) {
				// NO language stored => use nrowser language
				if (this.translate.getBrowserLang() !== undefined) {
					//this.translate.use(this.translate.getBrowserLang());
					const browserLang = this.translate.getBrowserLang();
					this.translate.use(browserLang.match(/de|da/) ? browserLang : 'de');
				}	// No broser-language =A Use default language
			} else {
				this.translate.use(lang); // Set stored language
			}
		});

		console.log('Current Language: ', this.translate.currentLang);
		if (this.translate.currentLang = "de") {
			this.isDE = true;
		} else {
			this.isDE = false;
		}
	}

}
