/**
* @author necsus <mail@necsus.com>
* @copyright ksze 2020
* @version 01.02.10
* @license licenses.txt
*
* @date 2020-09-30 09:18:03
**/

import { Injectable } from "@angular/core";


@Injectable()
export class Globals {
	
	constructor(
		
	){



	}



	devInfo: any = {};


}
