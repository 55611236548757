/**
* @author necsus <mail@necsus.com>
* @copyright ksze 2020
* @version 01.02.10
* @license licenses.txt
*
* @date 2020-09-30 09:18:03
**/

import { Injectable } from "@angular/core";

@Injectable()

export class AppSideMenus{
	items:any = [
    {
        "item_type": "title",
        "item_label": "menu.content",
        "item_var": "content",
        "item_link": "\/",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "undefined",
        "item_icon_left": "business-sharp",
        "item_color_icon_left": "undefined",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "menu.list",
        "item_var": "liste",
        "item_link": "\/liste",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "undefined",
        "item_icon_left": "list-circle-outline",
        "item_color_icon_left": "primary",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "menu.map",
        "item_var": "karte",
        "item_link": "\/karte",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "undefined",
        "item_icon_left": "map-outline",
        "item_color_icon_left": "primary",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
     },
     {
        "item_type": "inlink",
        "item_label": "menu.favorite",
        "item_var": "liste-bookmarks",
        "item_link": "\/liste-bookmarks",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "undefined",
        "item_icon_left": "star-outline",
        "item_color_icon_left": "primary",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
     },
     {
        "item_type": "title",
        "item_label": "menu.theme",
        "item_var": "theme",
        "item_link": "\/",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "undefined",
        "item_icon_left": "help-circle-sharp",
        "item_color_icon_left": "undefined",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "menu.intro",
        "item_var": "intro",
        "item_link": "\/content/intro",
        "item_value": "https:\/\/www.bdn.dk\/personendatenpolitik",
        "item_desc": "",
        "item_color_label": "undefined",
        "item_icon_left": "document-text-outline",
        "item_color_icon_left": "primary",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "menu.architects",
        "item_var": "architects",
        "item_link": "\/content/architects",
        "item_value": "https:\/\/www.bdn.dk\/personendatenpolitik",
        "item_desc": "",
        "item_color_label": "undefined",
        "item_icon_left": "people-outline",
        "item_color_icon_left": "primary",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "menu.glossary",
        "item_var": "glossary",
        "item_link": "\/content/glossary",
        "item_value": "https:\/\/www.bdn.dk\/personendatenpolitik",
        "item_desc": "",
        "item_color_label": "undefined",
        "item_icon_left": "list-outline",
        "item_color_icon_left": "primary",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "title",
        "item_label": "menu.help",
        "item_var": "help",
        "item_link": "\/",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "undefined",
        "item_icon_left": "help-circle-sharp",
        "item_color_icon_left": "undefined",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "menu.welcome",
        "item_var": "home",
        "item_link": "\/home",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "undefined",
        "item_icon_left": "help-circle-outline",
        "item_color_icon_left": "primary",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    /*{
        "item_type": "inlink",
        "item_label": "menu.faq",
        "item_var": "faqs",
        "item_link": "\/faqs",
        "item_value": "",
        "item_desc": "Oft gestellte Fragen",
        "item_color_label": "undefined",
        "item_icon_left": "help-circle",
        "item_color_icon_left": "secondary",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },*/
    {
        "item_type": "inlink",
        "item_label": "menu.privacy",
        "item_var": "privacy_policy",
        "item_link": "\/content/privacy",
        "item_value": "https:\/\/www.bdn.dk\/personendatenpolitik",
        "item_desc": "",
        "item_color_label": "undefined",
        "item_icon_left": "lock-closed-outline",
        "item_color_icon_left": "primary",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "menu.about",
        "item_var": "about_us",
        "item_link": "\/about-us",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "undefined",
        "item_icon_left": "people-circle-outline",
        "item_color_icon_left": "primary",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    },
    {
        "item_type": "inlink",
        "item_label": "menu.disclaimer",
        "item_var": "disclaimer",
        "item_link": "\/content/disclaimer",
        "item_value": "",
        "item_desc": "",
        "item_color_label": "undefined",
        "item_icon_left": "information-circle-outline",
        "item_color_icon_left": "primary",
        "item_icon_right": "",
        "item_color_icon_right": "undefined"
    }
] ;

}
