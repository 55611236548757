/**
* @author necsus <mail@necsus.com>
* @copyright ksze 2020
* @version 01.02.10
* @license licenses.txt
*
* @date 2020-09-30 09:18:03
**/

import { Pipe, PipeTransform } from "@angular/core";
import { Geolocation } from "@ionic-native/geolocation/ngx";

/**
 * GetDistance pipe
 * Get the distance to current location

 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */


@Pipe({
	name: "getDistance",
})

export class GetDistancePipe implements PipeTransform {

	constructor(
		public geolocation: Geolocation
	){	}

    static calcDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
        var deg2Rad = deg => {
            return deg * Math.PI / 180;
        }

        var r = 6371; // Radius of the earth in km
        var dLat = deg2Rad(lat2 - lat1);   
        var dLon = deg2Rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2Rad(lat1)) * Math.cos(deg2Rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = r * c; // Distance in km
        return d;
    }

    transform(gpsdata:any,gpsfrom:any): any{

        var tto:string;
        var tfrom:string;
        var test:number;
        //return "test km";
        //this.geolocation.getCurrentPosition().then((resp) => {
        //console.log("gpsfrom: "+gpsfrom)
        //console.log("gpsdata: "+gpsdata)


	    tto = gpsdata.split(",");
	    tfrom = gpsfrom.split(",");
  	
        //return this.calcDistance([resp.coords.latitude, resp.coords.longitude], [gpsdata]);
        test = GetDistancePipe.calcDistance(Number(tfrom[0]), Number(tfrom[1]),Number(tto[0]),Number(tto[1]));
  	    //console.log("Distance: ("+gpsfrom+" "+gpsdata+"): "+test.toFixed(1)+" km")
  	    return test.toFixed(1)+" km";
        //return "test km";
      
        //    }).catch((error) => {
        //      alert('Error getting location' + JSON.stringify(error));
        //    });
	}
}
